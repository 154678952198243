/*
Template Name: Minton - Responsive Bootstrap 4 Admin Dashboard
Author: CoderThemes
Version: 4.0.0
Website: https://coderthemes.com/
Contact: support@coderthemes.com
File: Main Css File
*/

/* Add application styles & imports to this file! */
@import '~vis-timeline/dist/vis-timeline-graph2d.min.css';

/* vjs-player.component.css */
@import '~video.js/dist/video-js.css';

//Fonts
@import "custom/fonts/fonts";

//Icons
@import "icons";

//Core files
@import "./node_modules/bootstrap/scss/functions";
@import "./node_modules/bootstrap/scss/variables";
@import "variables";
@import "./node_modules/bootstrap/scss/mixins.scss";


// Structure
@import "custom/structure/general";
@import "custom/structure/horizontal-nav";
@import "custom/structure/topbar";
@import "custom/structure/page-head";
@import "custom/structure/footer";
@import "custom/structure/right-sidebar";

//Components
@import "custom/components/helper";
@import "custom/components/social";
@import "custom/components/widgets";
@import "custom/components/custom-checkbox";
@import "custom/components/custom-radio";
@import "custom/components/ribbons";
@import "custom/components/print";
@import "custom/components/preloader";


// Plugins
@import "custom/plugins/waves";
@import "custom/plugins/slimscroll";
@import "custom/plugins/toaster";
@import "custom/plugins/sweetalert";
@import "custom/plugins/rangeslider";
@import "custom/plugins/multiple-select";
@import "custom/plugins/select2";
@import "custom/plugins/datepicker";
@import "custom/plugins/daterange";
@import "custom/plugins/summernote";
@import "custom/plugins/form-wizard";
@import "custom/plugins/dropzone";
@import "custom/plugins/datatable";
@import "custom/plugins/chartist";
@import "custom/plugins/google-maps";
@import "custom/plugins/calendar";
@import "custom/plugins/carousel";
@import "custom/plugins/ui-switch";

// Pages
@import "custom/pages/components-demo";
@import "custom/pages/logout";
@import "custom/pages/error";
@import "custom/pages/email";
@import "custom/pages/taskboard";
@import "custom/pages/timeline";
@import "custom/pages/pricing";
@import "custom/pages/gallery";


.vjs-poster {
  //background-size: cover !important;
}
