//
// slimscroll.scss
//

.slimScrollDiv {
    height: auto !important;
}

.scrollbar-track {
    background: transparent !important;
}