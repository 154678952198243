//
// gallery.scss
//

.filter-menu {
    margin-bottom: 20px;
  
    a {
      transition: all 0.3s ease-out;
      color: $gray-600;
      border-radius: 3px;
      padding: 5px 10px;
      display: inline-block;
      margin-bottom: 5px;
      font-weight: $font-weight-medium;
      font-family: $font-family-secondary;
      font-size: 13px;
      text-transform: uppercase;
      letter-spacing: 0.03rem;
      &:hover {
        background-color: rgba($primary, 0.2);
        color: $primary;
      }
    }
    a.active {
      background-color: rgba($primary, 0.2);
      color: $primary;
    }
  }
  
  // Gallary Thumb
  .gal-box {
    background-color: $card-bg;
    border-radius: 3px;
    border: $card-border-width solid $card-border-color;
    margin-bottom: $grid-gutter-width;
  
    .image-popup {
      padding: 10px;
      display: block;
  
      img {
        cursor: zoom-in;
      }
    }
  
    .gall-info {
      padding: 15px;
      border-top: 1px solid $gray-200;
      position: relative;
  
      h4 {
        display: block;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
  
      .gal-like-btn {
        position: absolute;
        right: 15px;
        font-size: 22px;
        top: 24px;
      }
    }
  }
  
  /* Preload images */
  body:after {
    //content: url(../images/img/close.png) url(../images/img/loading.gif) url(../images/img/prev.png)
    //  url(../images/img/next.png);
    display: none;
  }
  
  html.lb-disable-scrolling {
    overflow: hidden;
  }
  
  .lightboxOverlay {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9999;
    background-color: black;
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
    opacity: 0.8;
  }
  
  .lightbox {
    position: absolute;
    left: 0;
    width: 100%;
    z-index: 10000;
    text-align: center;
    line-height: 0;
    font-weight: normal;
    box-sizing: content-box;
    outline: none;
  }
  
  .lightbox .lb-image {
    height: auto;
    max-width: inherit;
    max-height: none;
    border-radius: 3px;
  
    /* Image border */
    border: 4px solid white;
  }
  
  .lightbox a img {
    border: none;
  }
  
  .lb-outerContainer {
    position: relative;
    *zoom: 1;
    width: 250px;
    height: 250px;
    margin: 0 auto;
    border-radius: 4px;
  
    /* Background color behind image.
         This is visible during transitions. */
    background-color: white;
  }
  
  .lb-outerContainer:after {
    content: "";
    display: table;
    clear: both;
  }
  
  .lb-loader {
    position: absolute;
    top: 43%;
    left: 0;
    height: 25%;
    width: 100%;
    text-align: center;
    line-height: 0;
  }
  
  .lb-cancel {
    display: block;
    width: 32px;
    height: 32px;
    margin: 0 auto;
    //background: url("../images/img/loading.gif") no-repeat;
  }
  
  .lb-nav {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 10;
  }
  
  .lb-container > .nav {
    left: 0;
  }
  
  .lb-nav a {
    outline: none;
    background-image: url("data:image/gif;base64,R0lGODlhAQABAPAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==");
  }
  
  .lb-prev,
  .lb-next {
    height: 100%;
    cursor: pointer;
    display: block;
  }
  
  .lb-nav a.lb-prev {
    width: 34%;
    left: 0;
    float: left;
    //background: url("../images/img/prev.png") left 48% no-repeat;
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
    -webkit-transition: opacity 0.6s;
    -moz-transition: opacity 0.6s;
    -o-transition: opacity 0.6s;
    transition: opacity 0.6s;
  }
  
  .lb-nav a.lb-prev:hover {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
  }
  
  .lb-nav a.lb-next {
    width: 64%;
    right: 0;
    float: right;
    //background: url("../images/img/next.png") right 48% no-repeat;
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
    -webkit-transition: opacity 0.6s;
    -moz-transition: opacity 0.6s;
    -o-transition: opacity 0.6s;
    transition: opacity 0.6s;
  }
  
  .lb-nav a.lb-next:hover {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
  }
  
  .lb-dataContainer {
    margin: 0 auto;
    padding-top: 5px;
    *zoom: 1;
    width: 100%;
    -moz-border-radius-bottomleft: 4px;
    -webkit-border-bottom-left-radius: 4px;
    border-bottom-left-radius: 4px;
    -moz-border-radius-bottomright: 4px;
    -webkit-border-bottom-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
  
  .lb-dataContainer:after {
    content: "";
    display: table;
    clear: both;
  }
  
  .lb-data {
    padding: 0 4px;
    color: #ccc;
  }
  
  .lb-data .lb-details {
    width: 85%;
    float: left;
    text-align: left;
    line-height: 1.1em;
  }
  
  .lb-data .lb-caption {
    font-size: 13px;
    font-weight: bold;
    line-height: 1em;
  }
  
  .lb-data .lb-caption a {
    color: #4ae;
  }
  
  .lb-data .lb-number {
    display: block;
    clear: left;
    padding-bottom: 1em;
    font-size: 12px;
    color: #999999;
  }
  
  .lb-data .lb-close {
    display: block;
    float: right;
    width: 30px;
    height: 30px;
    //background: url("../images/img/close.png") top right no-repeat;
    text-align: right;
    outline: none;
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=70);
    opacity: 0.7;
    -webkit-transition: opacity 0.2s;
    -moz-transition: opacity 0.2s;
    -o-transition: opacity 0.2s;
    transition: opacity 0.2s;
  }
  
  .lb-data .lb-close:hover {
    cursor: pointer;
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
  }
  
  /* animation */
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  
  @-webkit-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  
  @keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  
  @-webkit-keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  
  @keyframes fadeInOverlay {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 0.8;
    }
  }
  
  @-webkit-keyframes fadeInOverlay {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 0.8;
    }
  }
  
  @keyframes fadeOutOverlay {
    0% {
      opacity: 0.8;
    }
    100% {
      opacity: 0;
    }
  }
  
  @-webkit-keyframes fadeOutOverlay {
    0% {
      opacity: 0.8;
    }
    100% {
      opacity: 0;
    }
  }
  
  .fadeIn {
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
  }
  
  .fadeInOverlay {
    -webkit-animation-name: fadeInOverlay;
    animation-name: fadeInOverlay;
  }
  
  .fadeOut {
    -webkit-animation-name: fadeOut;
    animation-name: fadeOut;
  }
  
  .fadeOutOverlay {
    -webkit-animation-name: fadeOutOverlay;
    animation-name: fadeOutOverlay;
  }
  
  .animation {
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
  }
  
  .transition {
    /* For Safari 3.1 to 6.0 */
    -webkit-transition-property: all;
    -webkit-transition-timing-function: ease;
    /* Standard syntax */
    transition-property: all;
    transition-timing-function: ease;
  }
  
  /* animation */
  